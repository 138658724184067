<template>
  <div class="flex justify-center text-center pt-20">
    <div class="p-6 px-8 rounded-md overflow-hidden shadow-lg lg:w-1/4">
      <h1 class="text-secondary text-xl font-medium mb-5">
        Cambiar contraseña
      </h1>
      <Form
        @submit="onChangePassword"
        :validation-schema="{
          oldPassword: 'required',
          password: 'required',
          confirmPassword: 'required',
        }"
      >
        <div class="mb-4">
          <div class="relative w-full flex flex-wrap items-stretch">
            <span
              class="
                absolute
                z-10
                py-3
                pl-3
                w-8
                h-full
                leading-snug
                bg-transparent
                rounded
                text-base
                font-normal
                text-gray-400 text-center
                flex
                items-center
                justify-center
              "
            >
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7168 7.14802H11.5025V3.14802C11.5025 1.88552 10.4793 0.862305 9.2168 0.862305H4.78823C3.52573 0.862305 2.50251 1.88552 2.50251 3.14802V7.14802H1.28823C0.972154 7.14802 0.716797 7.40338 0.716797 7.71945V14.5766C0.716797 14.8927 0.972154 15.148 1.28823 15.148H12.7168C13.0329 15.148 13.2882 14.8927 13.2882 14.5766V7.71945C13.2882 7.40338 13.0329 7.14802 12.7168 7.14802ZM3.78823 3.14802C3.78823 2.59623 4.23644 2.14802 4.78823 2.14802H9.2168C9.76858 2.14802 10.2168 2.59623 10.2168 3.14802V7.14802H3.78823V3.14802ZM12.0025 13.8623H2.00251V8.43373H12.0025V13.8623ZM6.50251 11.3802V12.3266C6.50251 12.4052 6.5668 12.4694 6.64537 12.4694H7.35965C7.43823 12.4694 7.50251 12.4052 7.50251 12.3266V11.3802C7.64992 11.2743 7.75994 11.1245 7.81675 10.9521C7.87355 10.7798 7.87421 10.5939 7.81862 10.4211C7.76303 10.2484 7.65407 10.0977 7.50741 9.99087C7.36076 9.884 7.18397 9.82642 7.00251 9.82642C6.82105 9.82642 6.64427 9.884 6.49761 9.99087C6.35096 10.0977 6.24199 10.2484 6.18641 10.4211C6.13082 10.5939 6.13147 10.7798 6.18828 10.9521C6.24508 11.1245 6.35511 11.2743 6.50251 11.3802Z"
                  fill="#0057FF"
                />
              </svg>
            </span>
            <Field v-model="oldPassword" name="oldPassword" v-slot="{ field }">
              <input
                v-bind="field"
                type="password"
                autocomplete="current-password"
                class="
                  relative
                  py-3
                  px-2
                  pl-10
                  w-full
                  bg-white
                  rounded-lg
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none focus:shadow-outline
                "
                placeholder="Contraseña actual:"
              />
            </Field>
          </div>
          <div class="text-left">
            <ErrorMessage name="oldPassword" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
        <div class="mb-4">
          <div class="relative w-full flex flex-wrap items-stretch">
            <span
              class="
                absolute
                z-10
                py-3
                pl-3
                w-8
                h-full
                leading-snug
                bg-transparent
                rounded
                text-base
                font-normal
                text-gray-400 text-center
                flex
                items-center
                justify-center
              "
            >
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7168 7.14802H11.5025V3.14802C11.5025 1.88552 10.4793 0.862305 9.2168 0.862305H4.78823C3.52573 0.862305 2.50251 1.88552 2.50251 3.14802V7.14802H1.28823C0.972154 7.14802 0.716797 7.40338 0.716797 7.71945V14.5766C0.716797 14.8927 0.972154 15.148 1.28823 15.148H12.7168C13.0329 15.148 13.2882 14.8927 13.2882 14.5766V7.71945C13.2882 7.40338 13.0329 7.14802 12.7168 7.14802ZM3.78823 3.14802C3.78823 2.59623 4.23644 2.14802 4.78823 2.14802H9.2168C9.76858 2.14802 10.2168 2.59623 10.2168 3.14802V7.14802H3.78823V3.14802ZM12.0025 13.8623H2.00251V8.43373H12.0025V13.8623ZM6.50251 11.3802V12.3266C6.50251 12.4052 6.5668 12.4694 6.64537 12.4694H7.35965C7.43823 12.4694 7.50251 12.4052 7.50251 12.3266V11.3802C7.64992 11.2743 7.75994 11.1245 7.81675 10.9521C7.87355 10.7798 7.87421 10.5939 7.81862 10.4211C7.76303 10.2484 7.65407 10.0977 7.50741 9.99087C7.36076 9.884 7.18397 9.82642 7.00251 9.82642C6.82105 9.82642 6.64427 9.884 6.49761 9.99087C6.35096 10.0977 6.24199 10.2484 6.18641 10.4211C6.13082 10.5939 6.13147 10.7798 6.18828 10.9521C6.24508 11.1245 6.35511 11.2743 6.50251 11.3802Z"
                  fill="#0057FF"
                />
              </svg>
            </span>
            <Field v-model="password" name="password" v-slot="{ field }">
              <input
                v-bind="field"
                type="password"
                autocomplete="new-password"
                class="
                  relative
                  py-3
                  px-2
                  pl-10
                  w-full
                  bg-white
                  rounded-lg
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none focus:shadow-outline
                "
                placeholder="Nueva contraseña:"
              />
            </Field>
          </div>
          <div class="text-left">
            <ErrorMessage name="password" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
        <div class="mb-4">
          <div class="relative w-full flex flex-wrap items-stretch">
            <span
              class="
                absolute
                z-10
                py-3
                pl-3
                w-8
                h-full
                leading-snug
                bg-transparent
                rounded
                text-base
                font-normal
                text-gray-400 text-center
                flex
                items-center
                justify-center
              "
            >
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7168 7.14802H11.5025V3.14802C11.5025 1.88552 10.4793 0.862305 9.2168 0.862305H4.78823C3.52573 0.862305 2.50251 1.88552 2.50251 3.14802V7.14802H1.28823C0.972154 7.14802 0.716797 7.40338 0.716797 7.71945V14.5766C0.716797 14.8927 0.972154 15.148 1.28823 15.148H12.7168C13.0329 15.148 13.2882 14.8927 13.2882 14.5766V7.71945C13.2882 7.40338 13.0329 7.14802 12.7168 7.14802ZM3.78823 3.14802C3.78823 2.59623 4.23644 2.14802 4.78823 2.14802H9.2168C9.76858 2.14802 10.2168 2.59623 10.2168 3.14802V7.14802H3.78823V3.14802ZM12.0025 13.8623H2.00251V8.43373H12.0025V13.8623ZM6.50251 11.3802V12.3266C6.50251 12.4052 6.5668 12.4694 6.64537 12.4694H7.35965C7.43823 12.4694 7.50251 12.4052 7.50251 12.3266V11.3802C7.64992 11.2743 7.75994 11.1245 7.81675 10.9521C7.87355 10.7798 7.87421 10.5939 7.81862 10.4211C7.76303 10.2484 7.65407 10.0977 7.50741 9.99087C7.36076 9.884 7.18397 9.82642 7.00251 9.82642C6.82105 9.82642 6.64427 9.884 6.49761 9.99087C6.35096 10.0977 6.24199 10.2484 6.18641 10.4211C6.13082 10.5939 6.13147 10.7798 6.18828 10.9521C6.24508 11.1245 6.35511 11.2743 6.50251 11.3802Z"
                  fill="#0057FF"
                />
              </svg>
            </span>
            <Field
              v-model="confirmPassword"
              name="confirmPassword"
              v-slot="{ field }"
            >
              <input
                v-bind="field"
                type="password"
                class="
                  relative
                  py-3
                  px-2
                  pl-10
                  w-full
                  bg-white
                  rounded-lg
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none focus:shadow-outline
                "
                placeholder="Confirmar contraseña:"
              />
            </Field>
          </div>
          <div class="text-left">
            <ErrorMessage name="confirmPassword" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
        <Alert
          v-if="responseMessage"
          :type="responseType"
          :text="responseMessage"
        ></Alert>
        <div>
          <button
            class="
              rounded
              bg-primary
              py-2
              px-4
              text-white
              w-full
              flex
              justify-center
            "
          >
            <LoadingButtonIcon v-if="loadingChange" />
            Actualizar contraseña
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const store = useStore();

const { value: oldPassword } = useField("oldPassword");
const { value: password } = useField("password");
const { value: confirmPassword } = useField("confirmPassword");
const loadingChange = ref(false);
const responseType = ref(null);
const responseMessage = ref(null);

const onChangePassword = async (values, { resetForm }) => {
  try {
    loadingChange.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (password.value !== confirmPassword.value)
      throw Error("La confirmación de la contraseña es inválida");

    await store.dispatch("changePassword", {
      oldPassword: values.oldPassword,
      password: values.password,
    });

    responseType.value = "success";
    responseMessage.value = "Se actualizó la contraseña";

    resetForm();
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ha ocurrido un error al actualizar la contraseña";

    console.error(error);
  } finally {
    loadingChange.value = false;
  }
};
</script>
